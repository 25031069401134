import { isDefined } from "@clipboard-health/util-ts";
import { useGetWorkerShift } from "@src/appV2/redesign/WorkerShifts/api/useGetWorkerShift";
import { useIsOnCallWorkplace } from "@src/appV2/Shifts/Shift/useIsOnCallWorkplace";
import { type ReactElement } from "react";
import { useParams } from "react-router-dom";

interface ShiftDetailsPageSelectorProps {
  onCallPage: ReactElement;
  fallbackPage: ReactElement;
}

export function ShiftDetailsPageSelector(props: ShiftDetailsPageSelectorProps) {
  const { shiftId } = useParams<{ shiftId: string }>();

  const { data: shiftData } = useGetWorkerShift(shiftId ?? "", {
    enabled: isDefined(shiftId) && shiftId.length > 0,
  });

  const shift = shiftData?.response;

  const isOnCallShift = useIsOnCallWorkplace(shift?.facility?.userId);

  if (isOnCallShift) {
    return props.onCallPage;
  }

  return props.fallbackPage;
}
