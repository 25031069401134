import { useGetWorkerShift } from "@src/appV2/redesign/WorkerShifts/api/useGetWorkerShift";
import {
  useIsWorkerShiftsClockInRedesignEnabled,
  useIsWorkerShiftsTimekeepingRedesignEnabled,
} from "@src/appV2/redesign/WorkerShifts/api/useIsWorkerShiftsRedesignEnabled";
import { SHIFT_DETAILS_TIMEKEEPING_PATH } from "@src/appV2/redesign/WorkerShifts/path";
import { ShiftDetailsError } from "@src/appV2/redesign/WorkerShifts/ShiftDetails/ShiftDetailsError";
import { ShiftDetailsLoadingSkeleton } from "@src/appV2/redesign/WorkerShifts/ShiftDetails/ShiftDetailsLoadingSkeleton";
import { ShiftDetailsPage } from "@src/appV2/redesign/WorkerShifts/ShiftDetails/ShiftDetailsPage";
import { ShiftTimekeepingPage } from "@src/appV2/redesign/WorkerShifts/ShiftDetails/ShiftTimekeepingPage";
import { Redirect, Route, Switch, useLocation, useParams, useRouteMatch } from "react-router-dom";

import { MyShiftDetailsPage as LegacyShiftDetailsPage } from "../../../../app/hcpShifts/shiftDetails";

export function ShiftDetailsRoute() {
  const { path, url } = useRouteMatch();
  const location = useLocation();
  const { shiftId } = useParams<{ shiftId: string }>();

  const { data: shiftResponse, isLoading, isError } = useGetWorkerShift(shiftId);

  const isClockInRedesignEnabled = useIsWorkerShiftsClockInRedesignEnabled();
  const isTimekeepingRedesignEnabled = useIsWorkerShiftsTimekeepingRedesignEnabled();

  if (isLoading) {
    return <ShiftDetailsLoadingSkeleton />;
  }

  if (isError) {
    return <ShiftDetailsError />;
  }

  const isClockedIn = Boolean(shiftResponse.response.clockInOut?.start);
  const isOnTimekeepingPage = location.pathname.endsWith(SHIFT_DETAILS_TIMEKEEPING_PATH);

  if (isClockedIn && !isOnTimekeepingPage) {
    const redirectPath = `${path.replace(":shiftId", shiftId)}${SHIFT_DETAILS_TIMEKEEPING_PATH}`;
    return <Redirect to={redirectPath} />;
  }

  if (!isClockedIn && isOnTimekeepingPage) {
    return <Redirect to={url} />;
  }

  return (
    <Switch>
      <Route exact path={path}>
        {isClockInRedesignEnabled ? <ShiftDetailsPage /> : <LegacyShiftDetailsPage />}
      </Route>
      <Route path={`${path}${SHIFT_DETAILS_TIMEKEEPING_PATH}`}>
        {isTimekeepingRedesignEnabled ? <ShiftTimekeepingPage /> : <LegacyShiftDetailsPage />}
      </Route>
    </Switch>
  );
}
